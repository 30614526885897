import { APTOS_CONFIG } from 'data/constant';

export type ChainNames =
	| 'Local'
	| 'Avalanche'
	| 'Move-EVM'
	| 'Base'
	| 'Arbitrum'
	| 'Binance-Smart-Chain'
	| 'Move-Porto';

export enum Network {
	'Binance-Smart-Chain' = 'Binance-Smart-Chain',
	'Local' = 'Local',
	'Avalanche' = 'Avalanche',
	'Move-EVM' = 'Move-EVM',
	'Base' = 'Base',
	'Arbitrum' = 'Arbitrum',
	'Move-Porto' = 'Move-Porto',
}

export type NetworkConfig = {
	label: string;
	image: string;
	isTestChain: boolean;
	chainId: number;
	chainName: string;
	path: string;
	name: ChainNames;
	nativeCurrency: {
		name: string;
		symbol: string;
		decimals: number;
	};
	rpcUrls: string[];
	disabled?: boolean;
};

export const networkConfig: Record<ChainNames, any> = {
	Local: {
		label: 'Local',
		name: 'Local',
		image: '/networks/ethereum.svg',
		isTestChain: true,
		path: 'ethereum',
		chainId: 56,
		chainName: 'Ethereum',
		nativeCurrency: {
			name: 'ETH',
			symbol: 'eth',
			decimals: 18,
		},
		rpcUrls: [],
	},
	'Binance-Smart-Chain': {
		label: 'Binance Smart Chain',
		name: 'Binance-Smart-Chain',
		image: '/crypto/binance.svg',
		isTestChain: false, // BSC mainnet is not a test chain
		path: 'bnb',
		chainId: 56, // This is correct for BSC mainnet
		chainName: 'Binance Smart Chain',
		nativeCurrency: {
			name: 'Binance Coin',
			symbol: 'BNB', // BNB is the native currency for BSC
			decimals: 18,
		},
		rpcUrls: ['https://bsc-dataseed.binance.org/'], // Add an RPC URL for BSC
	},
	Base: {
		label: 'Base',
		name: 'Base',
		image: '/crypto/base.svg',
		isTestChain: false,
		path: 'base',
		chainId: 8453,
		chainName: 'Base Mainnet',
		nativeCurrency: {
			name: 'ETH',
			symbol: 'ETH',
			decimals: 18,
		},
		rpcUrls: ['https://mainnet.base.org'],
		blockExplorerUrls: ['https://basescan.org'],
	},

	Avalanche: {
		label: 'Avalanche',
		name: 'Avalanche',
		image: '/crypto/avax.svg',
		isTestChain: false,
		path: 'avalanche',
		chainId: 43114,
		chainName: 'Avalanche Mainnet',
		nativeCurrency: {
			name: 'AVAX',
			symbol: 'AVAX',
			decimals: 18,
		},
		rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
		blockExplorerUrls: ['https://cchain.explorer.avax.network/'],
		//disabled: true,
	},

	/* Dummy. to be corrected */
	Arbitrum: {
		label: 'Arbitrum',
		name: 'Arbitrum',
		image: '/images/arbitrum.svg',
		isTestChain: false,
		path: 'aalanche',
		chainId: 43114,
		chainName: 'Avalanche Mainnet',
		nativeCurrency: {
			name: 'AVAX',
			symbol: 'AVAX',
			decimals: 18,
		},
		rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
		blockExplorerUrls: ['https://cchain.explorer.avax.network/'],
		disabled: true,
	},

	'Move-EVM': {
		label: 'Movement EVM Testnet',
		image: '/crypto/movement.svg',
		name: 'Move-EVM',
		path: 'movement_parthenon',
		isTestChain: true,
		chainId: 30732,
		chainName: 'Move-EVM',
		nativeCurrency: {
			name: 'MOVE',
			symbol: 'MOVE',
			decimals: 18,
		},
		rpcUrls: ['https://mevm.devnet.imola.movementlabs.xyz'],
		blockExplorerUrls: ['https://explorer.devnet.imola.movementlabs.xyz/'],
	},
	'Move-Porto': {
		label: 'Movement Porto Testnet',
		image: '/crypto/movement.svg',
		name: 'Move-Porto',
		path: 'movement_porto',
		isTestChain: true,
		chainId: APTOS_CONFIG.id,
		chainName: 'testnet',
		nativeCurrency: {
			name: 'MOVE',
			symbol: 'MOVE',
			decimals: 18,
		},
		rpcUrls: [APTOS_CONFIG.rpcUrl],
		blockExplorerUrls: ['https://explorer.devnet.imola.movementlabs.xyz/'],
	},
};
