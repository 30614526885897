import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Drawer, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { networkConfig } from 'config';
import WalletIcon from '@mui/icons-material/Wallet';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { getErc20Contract } from 'utils/contractHelpers';
import { formatUnits } from 'ethers/lib/utils';
import { appActions } from 'store/app';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useAccount, useDisconnect } from 'wagmi';
import { useEthersSigner } from 'hooks/useEtherSigner';
import { NetworkTitle } from 'components/WalletDialog/NetworkTitle';
import { isTestNet } from 'utils/isTestNet';
import { TEST_NET_URL } from 'data/constant';
import LaunchIcon from '@mui/icons-material/Launch';
import { useAptosWallet } from '@razorlabs/wallet-kit';
import { getAptosBalance } from 'utils/getAptosBalance';
import { useAptosContractBalance } from 'hooks/useAptosContractBalance';

const TextButton = styled(Button)`
	border-radius: 50px;
	background-color: #235ee122;
	font-weight: 600;
	font-size: 20px;
	padding: 5px 20px;
`;

const Row = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

const StyledBox = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	margin-right: 20px;
	width: 100%;
	margin-bottom: 8px;
	border-radius: 4px;
	border: 1px solid #d6d6d6;
	cursor: pointer;
	background: rgb(249, 249, 249);

	&:hover {
		background-color: rgb(238, 238, 238);
	}
`;

export const ConnectionButton = () => {
	const [aptosBalance, setAptosBalance] = useState('0');
	const appState = useSelector((state: RootState) => state.app);
	const dispatch = useDispatch();
	const [balance, setBalance] = useState('0');
	const selectedVault = useSelector((state: RootState) => state.vault);
	const selectedAsset = selectedVault.token.find((d) => d.name === 'USDC');
	const { openConnectModal } = useConnectModal();
	const { address } = useAccount();
	const { aptosAccount } = useAptosContractBalance();
	const { disconnect } = useDisconnect();
	const signer: any = useEthersSigner();
	const mainNet = [
		{ ...networkConfig['Binance-Smart-Chain'] },
		{ ...networkConfig.Base },
		{ ...networkConfig.Avalanche },
		{ ...networkConfig.Arbitrum },
	];
	const {
		address: account,
		disconnect: aptosDisconnect,
		connected,
	} = useAptosWallet();

	const getAptosAccountBalance = useCallback(async () => {
		const res = await getAptosBalance(aptosAccount?.address || '');
		setAptosBalance(res);
	}, [aptosAccount]);

	const bal = selectedVault.isAptos
		? aptosBalance
		: parseFloat(parseFloat(balance).toFixed(2)).toLocaleString();

	const testNet = [
		{ ...networkConfig['Move-EVM'] },
		{ ...networkConfig['Move-Porto'] },
	];
	const addr = (address ? address : account) || '';

	const toggleDrawer = (Open: boolean) => (event: any) => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}

		if (selectedVault.isAptos) {
			getAptosAccountBalance();
		}
		dispatch(appActions.openDrawer(Open));
	};

	const fetchBalance = useCallback(async () => {
		try {
			if (address) {
				const assetContract = getErc20Contract(
					selectedAsset?.address || '',
					signer?.provider.getSigner(address),
				);
				const assetBalance = await assetContract.balanceOf(address);
				setBalance(
					formatUnits(assetBalance, selectedAsset?.decimalValue),
				);
			} else {
				setBalance('0');
			}
		} catch (e) {
			//
		}
	}, [address, signer, selectedAsset?.address, selectedAsset?.decimalValue]);

	const TestnetButton = () => {
		return (
			<>
				{!isTestNet() && (
					<>
						<Box height={32} />
						<Typography
							variant={'subtitle2'}
							color={'#777'}
							fontWeight={600}
						>
							Testnet
						</Typography>
						<Box height={12} />
						<StyledBox
							onClick={() => {
								window.open(TEST_NET_URL, '_blank');
							}}
						>
							<Box display="flex" alignItems="center">
								<img src={'/crypto/movement.svg'} height={24} />
								<Box
									sx={{
										width: 10,
									}}
								/>
								<Typography
									variant={'caption'}
									textAlign="center"
									color={'#111'}
									fontWeight={500}
								>
									Movement EVM Testnet
								</Typography>
							</Box>

							<LaunchIcon sx={{ fontSize: 15 }} />
						</StyledBox>
					</>
				)}
			</>
		);
	};

	const ConnectedPanel = () => {
		return (
			<>
				<Typography
					variant={'subtitle2'}
					color={'#777'}
					fontWeight={600}
				>
					Choose a Network
				</Typography>
				<Box height={16} />
				{(isTestNet() ? testNet : mainNet).map((d, index) => (
					<NetworkTitle key={index} {...d} />
				))}
				<TestnetButton />
				<Box height={64} />
				{openConnectModal && (
					<Button
						onClick={() => {
							dispatch(appActions.openWallet(true));
						}}
						sx={{
							height: 42,
						}}
						variant="contained"
						fullWidth
						color="primary"
					>
						<Typography
							variant={'button'}
							fontWeight="bold"
							py="6px"
							color={'#fff'}
							style={{ lineHeight: '120%' }}
						>
							Connect
						</Typography>
					</Button>
				)}
			</>
		);
	};

	const DisconnectedPanel = () => {
		return (
			<>
				<Row>
					<Row style={{ justifyContent: 'flex-start' }}>
						<WalletIcon sx={{ color: '#333' }} />
						<Box width={10} />
						<abbr
							title={addr?.toString()}
							style={{ textDecoration: 'none' }}
						>
							<Typography>
								{addr?.slice(0, 3)}...
								{addr?.slice(addr.length - 3, addr.length)}
							</Typography>
						</abbr>
					</Row>
				</Row>
				<Box height={20} />
				<Typography variant={'h5'} fontWeight={600} color={'#999'}>
					{selectedVault.currency}&nbsp;
					{bal}
				</Typography>
				<Box height={10} />
				<Box height={32} />
				<Typography variant={'subtitle2'} color={'#777'}>
					Choose a Network
				</Typography>
				<Box height={10} />
				{(isTestNet() ? testNet : mainNet).map((d, index) => (
					<NetworkTitle key={index} {...d} />
				))}
				<TestnetButton />
				<Box height={40} />
				<Button
					onClick={async () => {
						if (connected) {
							await aptosDisconnect();
						} else {
							disconnect();
						}
					}}
					sx={{
						height: 42,
					}}
					variant="contained"
					fullWidth
					color="primary"
				>
					<Typography
						variant={'button'}
						fontWeight="bold"
						py="6px"
						color={'#fff'}
						style={{ lineHeight: '120%' }}
					>
						Disconnect
					</Typography>
				</Button>
			</>
		);
	};

	useEffect(() => {
		getAptosAccountBalance();
		fetchBalance();
	}, [fetchBalance, getAptosAccountBalance]);

	const drawerContent = (
		<Box
			sx={{ width: 300, padding: 1.3, paddingTop: 3 }}
			role="presentation"
			onKeyDown={toggleDrawer(false)}
		>
			{!(address || account) && <ConnectedPanel />}
			{(address || account) && <DisconnectedPanel />}
		</Box>
	);

	return (
		<div>
			{!(address || account) && (
				<TextButton onClick={toggleDrawer(true)}>Connect</TextButton>
			)}
			{(address || account) && (
				<TextButton onClick={toggleDrawer(true)}>
					{addr.slice(0, 3)}...
					{addr.slice(addr.length - 3, addr.length)}
				</TextButton>
			)}
			<Drawer
				anchor="right"
				open={appState.isDrawerOpen}
				onClose={toggleDrawer(false)}
				style={{ zIndex: 100 }}
			>
				{drawerContent}
			</Drawer>
		</div>
	);
};
